
































import { Component, Prop, Vue } from "vue-property-decorator";
import { CarouselItem } from "@/types/components/carousel";

@Component
export default class Carousel extends Vue {
    @Prop({ type: Array as () => CarouselItem[], required: true })
    readonly items!: CarouselItem[];
}
